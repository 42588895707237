//@author: migrated by kate

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { VariableI } from "../../pojo/VariableI";
import {
    S25BpeVarsDropdownComponent,
    VariableWithOptions,
} from "../s25-dropdown/single-select/s25.bpe.vars.dropdown.component";
import { S25Help } from "../s25-help/s25.help.service";
import { DropDownItem } from "../../pojo/DropDownItem";

type VariableData = {
    variable?: VariableWithOptions;
    options: Record<string, boolean>;
    var?: string;
    preVar?: string;
    format?: VariableI;
    index?: string | number;
    substitute?: string[];
};

@TypeManagerDecorator("bpe-vars")
@Component({
    selector: "bpe-vars",
    template: `
        <div class="ngBpeVars">
            <p>
                Select event details you would like to include and copy the generated variable values into your
                {{ codeMode ? "code" : "template" }}. Clicking a variable link will copy that value to your clipboard.
            </p>

            <label class="dropdownLabel">Standard Event Variables</label>
            <s25-ng-info-message> Basic variables available in all events.</s25-ng-info-message>
            <ng-container *ngTemplateOutlet="variable; context: { type: 'event', state: standardEventVars }" />

            <label class="dropdownLabel">Event Roles</label>
            <s25-ng-info-message>
                Functions contacts may perform for an event (such as Emergency Contact, Facilitator, etc.). Select the
                role and the information about it you wish to display.
            </s25-ng-info-message>
            <s25-ng-role-dropdown
                class="c-margin-top--quarter"
                [(chosen)]="contactRoleVars.role"
                (chosenChange)="contactRoleVars.index = $event.itemId; setVariable(contactRoleVars)"
            ></s25-ng-role-dropdown>
            @if (contactRoleVars.role) {
                <div class="extraInput">
                    <label><span class="selectedVarLabel">Variable: </span></label>
                    <ng-container *ngTemplateOutlet="variable; context: { type: 'roles', state: contactRoleVars }" />
                </div>
            }

            <label class="dropdownLabel">Custom Attributes</label>
            <s25-ng-info-message>
                Custom data fields used to define additional information for events. Event custom attributes are
                associated with individual event types.
            </s25-ng-info-message>
            <s25-custom-attr-dropdown
                class="c-margin-top--quarter"
                [itemTypeId]="1"
                [(chosen)]="selectedAttribute"
                (chosenChange)="onCustAtrbSelect(selectedAttribute)"
                [whitelistedTypes]="['F', 'B', 'S', 'D', 'E', '6', '3', 'N', '4', 'X', 'T', 'R', '2']"
            ></s25-custom-attr-dropdown>
            @if (selectedAttribute) {
                <div>
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'Custom Attribute',
                            text: selectedAttribute.templateString,
                        }"
                    ></ng-container>
                    @if (type !== "document") {
                        <ng-container
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{
                                label: 'Pre Custom Attribute',
                                text: selectedAttribute.preTemplateString,
                            }"
                        ></ng-container>
                    }
                </div>
            }

            @if (!codeMode) {
                <label class="dropdownLabel">Formattable Date Variables</label>
                <s25-ng-info-message>
                    Select the desired variable then select a date or time format. A preview will show the display
                    format.
                    <a href="{{ templateHelpLink }}" s25Help [helpTopic]="'template_vars_date'">
                        Read more about date modification.
                    </a>
                </s25-ng-info-message>
                <ng-container *ngTemplateOutlet="variable; context: { type: 'dates', state: dateVars }" />
            }

            <label class="dropdownLabel">Organization Roles</label>
            <s25-ng-info-message>
                Functions contacts may perform for an organization. Select the role and the information about it you
                wish to display.
            </s25-ng-info-message>
            <s25-toggle-button
                [(modelValue)]="orgRoleSpecificOrg"
                [trueLabel]="'Specific Org'"
                [falseLabel]="'Primary Org'"
                (modelValueChange)="onOrgRoleToggle()"
            />
            @if (orgRoleSpecificOrg) {
                <div class="extraInput">
                    <label>
                        <span class="selectedVarLabel">Organization: </span>
                        <s25-organization-dropdown
                            [staticItems]="orgRoleOrgStaticItems"
                            [(chosen)]="orgRoleOrg"
                            (chosenChange)="onOrgRoleSelect()"
                        />
                    </label>
                </div>
            }
            @if (orgRoleSpecificOrg === false || orgRoleOrg) {
                <div class="extraInput">
                    <label>
                        <span class="selectedVarLabel">Role: </span>
                        <s25-ng-role-dropdown
                            [type]="2"
                            [(chosen)]="selectedOrgRole"
                            (chosenChange)="onOrgRoleSelect()"
                        ></s25-ng-role-dropdown>
                    </label>
                </div>
            }
            @if (selectedOrgRole) {
                <div class="extraInput">
                    <label>
                        <span class="selectedVarLabel">Variable: </span>
                        <s25-bpe-vars-dropdown
                            type="orgRoles"
                            [(chosen)]="selectedOrgRoleVar"
                            (chosenChange)="onOrgRoleVarSelect()"
                        ></s25-bpe-vars-dropdown>
                    </label>
                </div>
            }
            @if ((orgRoleSpecificOrg === false || orgRoleOrg) && selectedOrgRole && selectedOrgRoleVar) {
                <div>
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{ label: 'Role Variable', text: orgRoleVariable }"
                    ></ng-container>
                    @if (type !== "document") {
                        <ng-container
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{ label: 'Pre Role Variable', text: preOrgRoleVariable }"
                        ></ng-container>
                    }
                </div>
            }

            @if (tableBuilder && isToDoTemplate !== 1 && !codeMode) {
                <div class="tableBuilder">
                    <label class="dropdownLabel">Table Builder</label>
                    <s25-ng-info-message>
                        Create a custom table to present desired data from the event.
                    </s25-ng-info-message>
                    <s25-bpe-vars-dropdown
                        class="c-margin-top--quarter"
                        [(chosen)]="tableBuilder.type"
                        [type]="type === 'email' ? 'tables' : 'documentTables'"
                        (chosenChange)="onTableTypeSelect()"
                    ></s25-bpe-vars-dropdown>
                    @if (tableBuilder.type?.val === "occurrences") {
                        <s25-ng-checkbox
                            [(modelValue)]="tableBuilder.includeLocations"
                            (modelValueChange)="updateTableKey(); updateTableVar()"
                            >Include Locations
                        </s25-ng-checkbox>
                        <br />
                        <s25-ng-checkbox
                            [(modelValue)]="tableBuilder.includeResources"
                            (modelValueChange)="updateTableKey(); updateTableVar()"
                            >Include Resources
                        </s25-ng-checkbox>
                        <br />
                        @if (tableBuilder.includeLocations || tableBuilder.includeResources) {
                            <s25-ng-checkbox
                                [(modelValue)]="tableBuilder.isPref"
                                (modelValueChange)="updateTableKey(); updateTableVar()"
                                >Pref Variables
                            </s25-ng-checkbox>
                        }
                    }
                    @if (tableBuilder.type?.val && tableBuilder.type?.val !== "none") {
                        <div>
                            <s25-ng-table-builder
                                [(columns)]="tableBuilder.columns"
                                [variableType]="tableBuilder.variablesKey"
                                [dateFormatType]="type === 'email' ? 'tableDateFormats' : 'tableDocumentDateFormats'"
                                [hasDataColumn]="tableBuilder.type.hasFormat"
                                [hasWidthColumn]="type === 'document'"
                                [dataColumnName]="'Format'"
                                (columnsChange)="updateTableVar()"
                            >
                            </s25-ng-table-builder>
                            @if (tableBuilder.type.val && tableBuilder.templateVariable) {
                                <div>
                                    @if (!tableBuilder.isPref) {
                                        <ng-container
                                            [ngTemplateOutlet]="copy"
                                            [ngTemplateOutletContext]="{
                                                label: 'Table Variable',
                                                text: tableBuilder.templateVariable,
                                            }"
                                        ></ng-container>
                                    }
                                    @if (type !== "document" && !tableBuilder.isPref) {
                                        <ng-container
                                            [ngTemplateOutlet]="copy"
                                            [ngTemplateOutletContext]="{
                                                label: 'Pre Variable',
                                                text: tableBuilder.templatePreVariable,
                                            }"
                                        ></ng-container>
                                    }
                                    @if (tableBuilder.hasPref && tableBuilder.isPref) {
                                        <ng-container
                                            [ngTemplateOutlet]="copy"
                                            [ngTemplateOutletContext]="{
                                                label: 'Pref Variable',
                                                text: tableBuilder.templatePrefVariable,
                                            }"
                                        ></ng-container>
                                    }
                                    @if (tableBuilder.hasPref && tableBuilder.isPref && type !== "document") {
                                        <ng-container
                                            [ngTemplateOutlet]="copy"
                                            [ngTemplateOutletContext]="{
                                                label: 'PrePref Variable',
                                                text: tableBuilder.templatePrePrefVariable,
                                            }"
                                        ></ng-container>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            }

            @if (listBuilder && isToDoTemplate !== 1 && !codeMode) {
                <div class="tableBuilder">
                    <label class="dropdownLabel">List Builder</label>
                    <s25-ng-info-message>
                        Create a custom list to present desired data from the event.
                    </s25-ng-info-message>
                    <s25-bpe-vars-dropdown
                        class="c-margin-top--quarter"
                        [(chosen)]="listBuilder.type"
                        type="tables"
                        (chosenChange)="onListTypeSelect()"
                    ></s25-bpe-vars-dropdown>
                    @if (listBuilder.type?.val && listBuilder.type.val !== "none") {
                        <label>
                            <span class="selectedVarLabel">Variable: </span>
                            <s25-bpe-vars-dropdown
                                [type]="$any(listBuilder.type.val)"
                                [(chosen)]="listBuilder.variable"
                                (chosenChange)="onListVariableChange()"
                            ></s25-bpe-vars-dropdown>
                        </label>
                    }
                    @if (listBuilder.variable?.hasFormat) {
                        <label>
                            <span class="selectedVarLabel">Format: </span>
                            <s25-bpe-vars-dropdown
                                type="tableDateFormats"
                                [(chosen)]="listBuilder.format"
                                (chosenChange)="updateListVar()"
                            ></s25-bpe-vars-dropdown>
                        </label>
                    }
                    @if (listBuilder.type?.val && listBuilder.variable?.val && listBuilder.templateVariable) {
                        <div>
                            <ng-container
                                [ngTemplateOutlet]="copy"
                                [ngTemplateOutletContext]="{
                                    label: 'List Variable',
                                    text: listBuilder.templateVariable,
                                }"
                            ></ng-container>
                            @if (type !== "document") {
                                <ng-container
                                    [ngTemplateOutlet]="copy"
                                    [ngTemplateOutletContext]="{
                                        label: 'Pre Variable',
                                        text: listBuilder.templatePreVariable,
                                    }"
                                ></ng-container>
                            }
                            @if (listBuilder.hasPref) {
                                <ng-container
                                    [ngTemplateOutlet]="copy"
                                    [ngTemplateOutletContext]="{
                                        label: 'Pref Variable',
                                        text: listBuilder.templatePrefVariable,
                                    }"
                                ></ng-container>
                            }
                            @if (listBuilder.hasPref && type !== "document") {
                                <ng-container
                                    [ngTemplateOutlet]="copy"
                                    [ngTemplateOutletContext]="{
                                        label: 'PrePref Variable',
                                        text: listBuilder.templatePrePrefVariable,
                                    }"
                                ></ng-container>
                            }
                        </div>
                    }
                </div>
            }

            @if (type === "document") {
                <div class="documentVariables">
                    <h3>Document Variables</h3>
                    <label class="dropdownLabel">General Variables</label>
                    <s25-ng-info-message>
                        Special variables for information tables and a variety of other data groupings.
                    </s25-ng-info-message>
                    <ng-container *ngTemplateOutlet="variable; context: { type: 'wsVars', state: wsGeneralVars }" />
                    <label class="dropdownLabel">Table Variables</label>
                    <s25-ng-info-message>
                        These variables render as tables. Some of the tables have optional checkboxes to present
                        different information.
                    </s25-ng-info-message>
                    <ng-container *ngTemplateOutlet="variable; context: { type: 'docWsTables', state: wsTables }" />
                    @if (docScope === "event") {
                        <div>
                            <label class="dropdownLabel">Pricing Variables</label>
                            <s25-ng-info-message> Pricing information about the event.</s25-ng-info-message>
                            <ng-container
                                *ngTemplateOutlet="variable; context: { type: 'wsPricingVars', state: wsPricingVars }"
                            />
                        </div>
                    }
                    <label class="dropdownLabel">Organization Variables</label>
                    <s25-ng-info-message>
                        Variables containing information about the primary organization of the event.
                    </s25-ng-info-message>
                    <ng-container *ngTemplateOutlet="variable; context: { type: 'wsOrgVars', state: wsOrgVars }" />
                    <label class="dropdownLabel">Organization Custom Attributes</label>
                    <s25-ng-info-message>
                        Custom data fields used to define additional information for organizations. Organization custom
                        attributes are associated with individual organization types.
                    </s25-ng-info-message>
                    <s25-custom-attr-dropdown
                        class="c-margin-top--quarter"
                        [itemTypeId]="2"
                        [(chosen)]="selectedOrgAttribute"
                        (chosenChange)="onOrgCustAtrbSelect(selectedOrgAttribute)"
                        [whitelistedTypes]="['F', 'B', 'S', 'D', 'E', '6', '3', 'N', '4', 'X', 'T', 'R', '2']"
                    ></s25-custom-attr-dropdown>
                    @if (selectedOrgAttribute) {
                        <div>
                            <ng-container
                                [ngTemplateOutlet]="copy"
                                [ngTemplateOutletContext]="{
                                    label: 'Variable',
                                    text: selectedOrgAttribute.templateString,
                                }"
                            ></ng-container>
                        </div>
                    }
                    @if (docScope === "invoice") {
                        <div>
                            <label class="dropdownLabel">Invoice</label>
                            <s25-ng-info-message> General information about the invoice.</s25-ng-info-message>
                            <ng-container
                                *ngTemplateOutlet="variable; context: { type: 'wsInvoice', state: wsInvoice }"
                            />
                            <label class="dropdownLabel">Invoice Totals</label>
                            <s25-ng-info-message>
                                Variables containing totals for prices, tax, adjustments, and more.
                            </s25-ng-info-message>
                            <ng-container
                                *ngTemplateOutlet="
                                    variable;
                                    context: { type: 'wsInvoiceTotals', state: wsInvoiceTotals }
                                "
                            />
                            <label class="dropdownLabel">Invoice Payments</label>
                            <s25-ng-info-message>
                                Variables containing information pertaining to payments made towards the event.
                            </s25-ng-info-message>
                            <s25-bpe-vars-dropdown
                                [type]="'wsPaymentType'"
                                [(chosen)]="wsInvoicePayments.paymentType"
                                (chosenChange)="
                                    wsInvoicePayments.substitute = [$event.val]; setVariable(wsInvoicePayments)
                                "
                            />
                            @if (wsInvoicePayments.paymentType) {
                                <div class="c-margin-top--quarter">
                                    <ng-container
                                        *ngTemplateOutlet="
                                            variable;
                                            context: { type: 'wsInvoicePayment', state: wsInvoicePayments }
                                        "
                                    />
                                </div>
                            }
                        </div>
                    }
                    @if (docScope === "reservation") {
                        <div>
                            <label class="dropdownLabel">Reservation Variables</label>
                            <s25-ng-info-message>
                                Information about specific occurrences and segments of an event.
                            </s25-ng-info-message>
                            <ng-container
                                *ngTemplateOutlet="variable; context: { type: 'wsRsrVars', state: wsReservationVars }"
                            />
                            <label class="dropdownLabel">Reservation Formattable Date Variables</label>
                            <s25-ng-info-message>
                                Select the desired variable then select a date or time format. A preview will show the
                                display format.
                                <a href="{{ documentHelpLink }}" s25Help [helpTopic]="'document_vars_date'">
                                    Read more about date modification.</a
                                >
                            </s25-ng-info-message>
                            <ng-container
                                *ngTemplateOutlet="variable; context: { type: 'wsRsrDateVars', state: dateVars }"
                            />
                        </div>
                    }
                    <div>
                        <label class="dropdownLabel">Resources by Id</label>
                        <s25-ng-info-message>
                            Determine if you want to "Include All" then optionally check the box. Use the "Select
                            Resources" button to choose one or more resources. Then, choose display options.
                        </s25-ng-info-message>
                        <div class="c-margin-bottom--single c-margin-top--half">
                            <s25-ng-checkbox [(modelValue)]="allResources" (modelValueChange)="onAllResourceChange()">
                                Include All
                            </s25-ng-checkbox>
                        </div>
                        <s25-ng-multiselect-search-criteria
                            [type]="'resources'"
                            [modelBean]="resourceBean"
                            [popoverOnBody]="true"
                        ></s25-ng-multiselect-search-criteria>
                        <p class="c-margin-top--single">{{ resourceNameString }}</p>
                    </div>
                    @if ((resourceIdString && resourceIdString.length > 0) || allResources) {
                        <div>
                            <label class="c-margin-top--half">
                                <span class="selectedVarLabel">Display Options: </span>
                                <s25-bpe-vars-dropdown
                                    type="wsResourceFormats"
                                    [(chosen)]="selectedResourceWsVar"
                                    (chosenChange)="onWsResourceVarSelect()"
                                ></s25-bpe-vars-dropdown>
                            </label>
                            <ng-container
                                [ngTemplateOutlet]="copy"
                                [ngTemplateOutletContext]="{
                                    label: 'Variable',
                                    text: selectedResourceWsVar?.templateString,
                                }"
                            ></ng-container>
                        </div>
                    }
                    <div>
                        <label class="dropdownLabel">Resources by Category</label>
                        <s25-ng-info-message>
                            Determine if you want to "Include All" then optionally check the box. Use the "Select
                            Categories" button to choose one or more categories. Then, choose display options.
                        </s25-ng-info-message>
                        <div class="c-margin-bottom--single c-margin-top--half">
                            <s25-ng-checkbox [(modelValue)]="allResCats" (modelValueChange)="onAllResCatsChange()">
                                Include All
                            </s25-ng-checkbox>
                        </div>
                        <s25-ng-multiselect-search-criteria
                            [type]="'resourceCategories'"
                            [modelBean]="resCatBean"
                            [popoverOnBody]="true"
                        ></s25-ng-multiselect-search-criteria>
                        <p class="c-margin-top--single">{{ resCatNameString }}</p>
                    </div>
                    @if ((resCatString && resCatString.length > 0) || allResCats) {
                        <div>
                            <label class="c-margin-top--half">
                                <span class="selectedVarLabel">Display Options: </span>
                                <s25-bpe-vars-dropdown
                                    type="wsResourceFormats"
                                    [(chosen)]="selectedResCatWsVar"
                                    (chosenChange)="onWsResCatVarSelect()"
                                ></s25-bpe-vars-dropdown>
                            </label>
                            <ng-container
                                [ngTemplateOutlet]="copy"
                                [ngTemplateOutletContext]="{
                                    label: 'Variable',
                                    text: selectedResCatWsVar?.templateString,
                                }"
                            ></ng-container>
                        </div>
                    }
                </div>
            }

            <ng-template #copy let-text="text" let-label="label">
                <label class="copy">
                    <span class="selectedVarLabel">{{ label }}: </span>
                    <a
                        class="c-textButton tooltipButton"
                        title="Copy to Clipboard"
                        aria-label="copy event variable to clipboard"
                        (click)="copyTextToClipBoard(text)"
                        (keyup.enter)="copyTextToClipBoard(text)"
                        tabindex="0"
                    >
                        {{ text }}
                        <span class="tooltipBefore">Copy to Clipboard</span>
                        <span class="tooltipAfter">Copied!</span>
                    </a>
                </label>
            </ng-template>

            <ng-template #variable let-type="type" let-state="state">
                <s25-bpe-vars-dropdown
                    class="c-margin-top--quarter"
                    [type]="type"
                    [(chosen)]="state.variable"
                    (chosenChange)="setVariable(state)"
                    [scope]="docScope"
                ></s25-bpe-vars-dropdown>
                @if (state.variable?.checkboxes) {
                    <div class="checkboxes">
                        @for (option of state.variable.checkboxes; track option) {
                            <s25-ng-checkbox
                                [(modelValue)]="state.options[option.id]"
                                (modelValueChange)="setVariable(state)"
                            >
                                {{ option.label }}
                            </s25-ng-checkbox>
                        }
                    </div>
                }
                @if (state.variable?.hasFormat) {
                    <label class="c-margin-top--quarter">
                        <span class="selectedVarLabel">Format: </span>
                        <s25-bpe-vars-dropdown
                            type="dateFormats"
                            [(chosen)]="state.format"
                            (chosenChange)="setVariable(state)"
                            [scope]="docScope"
                        ></s25-bpe-vars-dropdown>
                    </label>
                }
                @if (state.variable?.hasFormat && state.format) {
                    <div>Format Example: {{ state.format.ex }}</div>
                }
                @if (state.var) {
                    <div>
                        <ng-container
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{ label: 'Variable', text: state.var }"
                        ></ng-container>
                        @if (state.preVar) {
                            <ng-container
                                [ngTemplateOutlet]="copy"
                                [ngTemplateOutletContext]="{ label: 'Pre Variable', text: state.preVar }"
                            ></ng-container>
                        }
                    </div>
                }
            </ng-template>
        </div>
    `,
    styles: `
        .ngBpeVars .dropdownLabel {
            display: inline-block !important;
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0;
            width: min(100%, 225px);
        }

        .extraInput {
            margin-top: 0.5em;
        }

        .copy {
            margin-top: 0.5em;
            display: block;
        }

        .documentVariables {
            margin-top: 1em;
            border-top: 1px solid rgba(255, 255, 255, 0.24);
            padding-top: 1em;
        }

        .checkboxes {
            display: flex;
            gap: 0.5em;
            flex-wrap: wrap;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.Emulated,
})
export class BpeVarsComponent implements OnInit, OnChanges {
    //Document Management of Event Save Emails
    @Input() type: string = "email";
    //In document management, is the report scoped to the event or reservation
    @Input() docScope: string = "event";
    @Input() isToDoTemplate?: number = 0;
    @Input() codeMode: boolean;

    selectedAttribute: VariableI;
    selectedOrgAttribute: VariableI;
    standardEventVars: VariableData = { options: {} };
    dateVars: VariableData = { options: {} };
    contactRoleVars: VariableData & { role?: DropDownItem } = { options: {} };
    //ws variables used in document management
    wsGeneralVars: VariableData = { options: {} };
    selectedResourceWsVar: VariableI;
    selectedResCatWsVar: VariableI;
    wsReservationVars: VariableData = { options: {} };
    wsOrgVars: VariableData = { options: {} };
    wsPricingVars: VariableData = { options: {} };
    wsInvoice: VariableData = { options: {} };
    wsInvoiceTotals: VariableData = { options: {} };
    wsInvoicePayments: VariableData & { paymentType?: VariableI } = { options: {} };
    wsTables: VariableData = { options: {} };
    tableBuilder: {
        type?: VariableI;
        includeLocations?: boolean; // Only for occurrences
        includeResources?: boolean; // Only for occurrences
        isPref?: boolean; // Only for occurrences
        hasPref?: boolean;
        variablesKey?: keyof typeof S25BpeVarsDropdownComponent.Items;
        templateVariable?: string;
        templatePreVariable?: string;
        templatePrefVariable?: string;
        templatePrePrefVariable?: string;
        columns: {
            header: string;
            math?: string;
            format?: VariableI;
            variable?: VariableI;
            width?: number;
        }[];
    } = { columns: [{ header: "New Column", width: 1 }] };
    listBuilder: {
        type?: VariableI;
        variable?: VariableI;
        format?: VariableI;
        hasPref?: boolean;
        templateVariable?: string;
        templatePreVariable?: string;
        templatePrefVariable?: string;
        templatePrePrefVariable?: string;
    } = {};
    resourceIdString: string = "";
    resourceNameString: string = "";
    resourceBean: any = {};
    resCatString: string = "";
    resCatNameString: string = "";
    resCatBean: any = {};
    date = new Date();
    allResources: boolean = false;
    allResCats: boolean = false;
    templateHelpLink = S25Help.getHelpUrl("template_vars_date");
    documentHelpLink = S25Help.getHelpUrl("document_vars_date");

    orgRoleOrgStaticItems: DropDownItem[] = [
        {
            dropDownOrigName: "Primary Organization",
            isSelected: true,
            itemId: 0,
            itemName: "Primary Organization",
            noAnchorClick: true,
            txt: "Primary Organization",
            val: "",
        },
    ];
    orgRoleSpecificOrg: boolean;
    orgRoleOrg: DropDownItem;
    selectedOrgRole: VariableI;
    selectedOrgRoleVar: VariableI;
    orgRoleVariable: any;
    preOrgRoleVariable: any;

    convertToPre(val: string) {
        return "pre" + val.charAt(0).toUpperCase() + val.substring(1);
    }

    copyTextToClipBoard(text: string) {
        S25Util.copyToClipboard(text);
    }

    getIndex(index: string | number) {
        return this.codeMode ? `[${index}]` : `.${index}`;
    }

    wrapVar(variable: string, index?: string | number) {
        const i = index || Number(index) === 0 ? this.getIndex(index) : "";
        if (this.codeMode) return `${variable}${i}`;
        return `{{${variable}${i}}}`;
    }

    onCustAtrbSelect(tvar: VariableI) {
        tvar.templateString = this.wrapVar("$pro.vars.customAttributes", tvar.itemId);
        tvar.preTemplateString = this.wrapVar("$pro.vars.preCustomAttributes", tvar.itemId);
    }

    onOrgCustAtrbSelect(tvar: VariableI) {
        tvar.templateString = this.wrapVar("ws.org.CustomAttribute", tvar.itemId);
    }

    onOrgRoleToggle() {
        if (this.orgRoleSpecificOrg && !this.orgRoleOrg) {
            this.orgRoleVariable = null;
            this.preOrgRoleVariable = null;
        }
        this.onOrgRoleSelect();
        this.cd.detectChanges();
    }

    onOrgRoleSelect() {
        if (!this.selectedOrgRoleVar) return;
        if (this.orgRoleSpecificOrg && !this.orgRoleOrg) return;
        this.onOrgRoleVarSelect();
    }

    onAllResourceChange() {
        if (this.allResources) {
            this.resourceNameString = "";
            this.resourceIdString = "ALL";
        }
        this.onWsResourceVarSelect();
    }

    onAllResCatsChange() {
        if (this.allResCats) {
            this.resCatNameString = "";
            this.resCatString = "ALL";
        }
        this.onWsResCatVarSelect();
    }

    onWsResourceVarSelect() {
        //{{ws.rsrv.RsrcName_Inst-ID.###,###,###}}
        if (this.selectedResourceWsVar) {
            this.selectedResourceWsVar.templateString = this.wrapVar(
                this.selectedResourceWsVar.val + "-ID",
                this.resourceIdString,
            );
        }
        this.cd.detectChanges();
    }

    onWsResCatVarSelect() {
        //{{ws.rsrv.RsrcName_Inst-Cat.###,###,###}}
        if (this.selectedResCatWsVar) {
            this.selectedResCatWsVar.templateString = this.wrapVar(
                this.selectedResCatWsVar.val + "-Cat",
                this.resCatString,
            );
        }
        this.cd.detectChanges();
    }

    onOrgRoleVarSelect() {
        if (this.orgRoleSpecificOrg && !this.orgRoleOrg) {
            return alert("Please select an organization");
        }
        if (!this.selectedOrgRole) {
            return alert("Please select and organization role");
        }

        const org = this.orgRoleSpecificOrg
            ? `organizations${this.getIndex(this.orgRoleOrg.itemId)}`
            : `primaryOrganization`;
        const preOrg = this.convertToPre(org);
        const role = `contacts${this.getIndex(this.selectedOrgRole.itemId)}`;
        const data = this.selectedOrgRoleVar.val;
        this.orgRoleVariable = this.wrapVar(`$pro.vars.${org}.${role}.${data}`);
        this.preOrgRoleVariable = this.wrapVar(`$pro.vars.${preOrg}.${role}.${data}`);
    }

    onTableTypeSelect() {
        const { type } = this.tableBuilder;
        const variablesKey = type.val as keyof typeof S25BpeVarsDropdownComponent.Items;
        this.tableBuilder = {
            type,
            variablesKey,
            hasPref: ["locations", "resources"].includes(type.val),
            columns: this.getDefaultTableColumns(variablesKey),
        };
        this.updateTableVar();
        this.cd.detectChanges();
    }

    getDefaultTableColumns(variablesKey: keyof typeof S25BpeVarsDropdownComponent.Items) {
        if (this.tableBuilder.type.val === "none") return [];
        return (S25BpeVarsDropdownComponent.Items[variablesKey] as any[]).map((item) => ({
            header: item.txt,
            variable: item,
            width: item.width,
        }));
    }

    updateTableKey() {
        const { type, includeResources, includeLocations } = this.tableBuilder;
        let key = type.val as keyof typeof S25BpeVarsDropdownComponent.Items;
        // includeLocations and includeResources are only present on occurrences
        this.tableBuilder.hasPref = key === "locations" || key === "resources" || includeResources || includeLocations;
        this.tableBuilder.isPref = this.tableBuilder.hasPref && this.tableBuilder.isPref;
        if (includeLocations) key += "Locations";
        if (includeResources) key += "Resources";
        if (this.tableBuilder.isPref && (includeLocations || includeResources)) key += "Pref";
        this.tableBuilder.variablesKey = key;
        this.tableBuilder.columns = this.getDefaultTableColumns(key);
        this.cd.detectChanges();
    }

    updateTableVar() {
        // {{for:$pro.vars.occurrences:rsrvState:State||dates.rsrvStartDate|mediumDate:Start||rsrvStateName:State:end-for}}
        const { type, includeResources, includeLocations } = this.tableBuilder;
        const isDocument = this.type === "document";
        const columns = this.tableBuilder.columns
            .filter((column) => !!column.variable?.val)
            .map((column) => {
                const { header, variable, format, width } = column;
                const formatString = format ? ` | ${format.val}` : "";
                const columnWidth = isDocument ? ` : ${width}` : "";
                const variableName = isDocument ? variable.wsVal || variable.val : variable.val;
                return `${variableName}${formatString} : ${header}${columnWidth}`;
            })
            .join(" || ");
        if (columns) {
            let variable = type.val;
            if (type.val === "occurrences" && includeLocations) variable += "Locations";
            if (type.val === "occurrences" && includeResources) variable += "Resources";
            const typeStr = variable.slice(0, 1).toUpperCase() + variable.slice(1);
            const tablePrefix = isDocument ? "ws.flexTable" : "$pro.vars";

            this.tableBuilder.templateVariable = `{{for: ${tablePrefix}.${variable}: ${columns} :end-for}}`;
            this.tableBuilder.templatePreVariable = `{{for: ${tablePrefix}.pre${typeStr}: ${columns} :end-for}}`;
            this.tableBuilder.templatePrefVariable = `{{for: ${tablePrefix}.pref${typeStr}: ${columns} :end-for}}`;
            this.tableBuilder.templatePrePrefVariable = `{{for: ${tablePrefix}.prePref${typeStr}: ${columns} :end-for}}`;
        } else this.tableBuilder.templateVariable = "";
        this.cd.detectChanges();
    }

    onListTypeSelect() {
        this.listBuilder = {
            type: this.listBuilder.type,
            hasPref: ["locations", "resources"].includes(this.listBuilder.type.val),
        };
        this.updateListVar();
        this.cd.detectChanges();
    }

    onListVariableChange() {
        delete this.listBuilder.format;
        this.updateListVar();
    }

    updateListVar() {
        const key = this.listBuilder.type.val;
        const Key = key.slice(0, 1).toUpperCase() + key.slice(1);
        const variable = this.listBuilder.variable?.val;
        const format = this.listBuilder.format?.val ? `| ${this.listBuilder.format?.val} ` : "";
        this.listBuilder.templateVariable = `{{list: $pro.vars.${key} : ${variable} ${format}}}`;
        this.listBuilder.templatePreVariable = `{{list: $pro.vars.pre${Key} : ${variable} ${format}}}`;
        this.listBuilder.templatePrefVariable = `{{list: $pro.vars.pref${Key} : ${variable} ${format}}}`;
        this.listBuilder.templatePrePrefVariable = `{{list: $pro.vars.prePref${Key} : ${variable} ${format}}}`;
        this.cd.detectChanges();
    }

    setVariable(state: typeof this.wsTables) {
        if (!state?.variable) return;

        let varStr = state.variable.val;
        state.var = null;
        state.preVar = null;

        if (state.variable.checkboxes) {
            const variable = state.variable.vars.find((variable) => {
                for (let option of state.variable.checkboxes) {
                    const chosenValue = !!state.options[option.id];
                    const variableValue = !!variable.options[option.id as keyof typeof variable.options];
                    if (chosenValue !== variableValue) return false;
                }
                return true;
            });
            if (!variable) varStr = undefined;
            else varStr = variable.var;
        }

        if (!varStr) {
            this.cd.detectChanges();
            return;
        }

        if (state.variable.substitute) {
            for (let i of S25Util.range(state.substitute?.length || 0)) {
                const re = new RegExp("\\$" + (i + 1), "g");
                varStr = varStr.replace(re, state.substitute[i]);
            }
        }

        if (state.variable.indexed) {
            if (state.index || Number(state.index) === 0) {
                varStr += this.getIndex(state.index);
            }
        }

        const isWs = varStr?.startsWith("ws");
        if (state.variable.hasFormat && state.format) {
            if (isWs) {
                varStr += `|${state.format.val}`; // WS does not support spaces around pipe
            } else {
                varStr += ` | ${state.format.val}`;
            }
        }

        state.var = this.wrapVar(isWs ? varStr : `$pro.vars.${varStr}`);
        state.preVar = isWs ? "" : this.wrapVar(`$pro.vars.${this.convertToPre(varStr)}`);
        if (state.variable.noPre) state.preVar = null;

        this.cd.detectChanges();
    }

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.docScope) this.cd.detectChanges();
    }

    ngOnInit() {
        this.resourceBean.onDone = () => {
            this.resourceIdString = "";
            for (let r of this.resourceBean.selectedItems) {
                this.resourceNameString = this.resourceNameString + r.itemName + ", ";
                this.resourceIdString = this.resourceIdString + r.itemId + ",";
            }
            //remove trailing comma
            this.resourceIdString = this.resourceIdString.slice(0, -1);
            this.resourceNameString = this.resourceNameString.slice(0, -2);
            this.allResources = false;
            this.onWsResourceVarSelect();
            this.resourceBean.selectedItems = [];
        };

        this.resCatBean.onDone = () => {
            this.resCatString = "";
            for (let r of this.resCatBean.selectedItems) {
                this.resCatNameString = this.resCatNameString + r.itemName + ", ";
                this.resCatString = this.resCatString + r.itemId + ",";
            }
            //remove trailing comma
            this.resCatString = this.resCatString.slice(0, -1);
            this.resCatNameString = this.resCatNameString.slice(0, -2);
            this.allResCats = false;
            this.onWsResCatVarSelect();
            this.resCatBean.selectedItems = [];
        };
    }

    protected readonly S25Help = S25Help;
}
